<template>
  <div class="bg">
    <header>
      <img
        object-fit="contain"
        class="head_logo"
        src="../assets/head_logo.png"
        alt=""
      />
      <div @click="changeMenu" class="nav_btn">
        <div class="nav_item"></div>
        <div class="nav_item"></div>
        <div class="nav_item"></div>
      </div>
    </header>
    <div
      v-if="pageindex != 0 && pageindex != 6 && pageindex != 7"
      class="line"
    ></div>
    <div class="swiper_box">
      <div class="swiper-wrapper">
        <div class="swiper-slide bg_yel">
          <div class="bg_img"></div>
          <div class="p1_container">
            <div class="p1_box">
              <img
                object-fit="contain"
                class="left_icon"
                src="../assets/left_icon1.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="right_icon"
                src="../assets/right_icon1.png"
                alt=""
              />
              <!--  -->
              <img
                object-fit="contain"
                class="p1_logo"
                src="../assets/p1_logo.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p1_slogan"
                src="../assets/p1_slogan.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p1_text"
                src="../assets/p1_text.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p1_phone"
                src="../assets/p1_phone.png"
                alt=""
              />
              <img
                v-if="!showMenu"
                object-fit="contain"
                class="p1_code"
                src="../assets/p1_code.png"
                alt=""
              />
              <!--  -->
              <img
                object-fit="contain"
                class="yel_star"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="yel_bottom"
                src="../assets/bottom_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="yel_top"
                src="../assets/top_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="yel_star2"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="yel_rocket"
                src="../assets/rocket.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="yel_dimon"
                src="../assets/dimon.png"
                alt=""
              />
            </div>
          </div>
          <img
            object-fit="contain"
            class="foot_left"
            src="../assets/foot_left.png"
            alt=""
          />
          <img
            object-fit="contain"
            class="foot_right"
            src="../assets/foot_right.png"
            alt=""
          />
          <img
            object-fit="contain"
            class="bottom_arrow"
            src="../assets/arrow.png"
            alt=""
          />
        </div>
        <div class="swiper-slide">
          <div class="bg_img"></div>
          <div class="svg_box">
            <svg
              class="waves"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
              shape-rendering="auto"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160,35.6 c 30,0,58-6.6,88-6.6 s58,6.6,88,6.6s58-6.6,88-6.6s58,6.6,88,6.6V59h-352V35.6z"
                />
              </defs>
              <g class="parallax">
                <use xlink:href="#gentle-wave" x="48" y="0" fill="#fffbeb" />
                <use xlink:href="#gentle-wave" x="48" y="0" fill="#ffcc22" />
              </g>
            </svg>
          </div>
          <div class="p2_container">
            <div class="p2_box">
              <img
                object-fit="contain"
                class="p2_title"
                src="../assets/p2_title.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p2_sub"
                src="../assets/p2_sub.png"
                alt=""
              />
              <div class="p2_span">&nbsp;</div>
              <img
                object-fit="contain"
                class="p2_text"
                src="../assets/p2_text.png"
                alt=""
              />
              <!-- <div class="p2_phone_box"> -->
              <!-- <div class="p2_contain"> -->
              <img
                object-fit="contain"
                class="p2_phone"
                src="../assets/p2_phone1.png"
                alt=""
              />
              <!-- <img
                    object-fit="contain"
                    class="p2_img1"
                    src="../assets/p2_img1.png"
                    alt=""
                  />
                  <img
                    object-fit="contain"
                    class="p2_img2"
                    src="../assets/p2_img2.png"
                    alt=""
                  /> -->
              <!-- </div> -->
              <!-- </div> -->

              <img
                object-fit="contain"
                class="p2_search"
                src="../assets/p2_search.png"
                alt=""
              />
              <!--  -->
              <img
                object-fit="contain"
                class="white_cold"
                src="../assets/cold.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_star"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_bottom"
                src="../assets/bottom_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_top"
                src="../assets/top_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_star2"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_rocket"
                src="../assets/rocket.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_dimon"
                src="../assets/bluedimon.png"
                alt=""
              />
            </div>
          </div>
          <img
            object-fit="contain"
            class="bottom_arrow"
            src="../assets/arrow.png"
            alt=""
          />
        </div>
        <div class="swiper-slide">
          <div class="bg_img"></div>
          <div class="svg_box">
            <svg
              class="waves"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
              shape-rendering="auto"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160,35.6 c 30,0,58-6.6,88-6.6 s58,6.6,88,6.6s58-6.6,88-6.6s58,6.6,88,6.6V59h-352V35.6z"
                />
              </defs>
              <g class="parallax">
                <use xlink:href="#gentle-wave" x="48" y="0" fill="#fffbeb" />
                <use xlink:href="#gentle-wave" x="48" y="0" fill="#ffcc22" />
              </g>
            </svg>
          </div>
          <div class="p2_container">
            <div class="p2_box">
              <img
                object-fit="contain"
                class="p3_title"
                src="../assets/p3_title.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p3_sub"
                src="../assets/p3_sub.png"
                alt=""
              />
              <div class="p3_span">&nbsp;</div>
              <img
                object-fit="contain"
                class="p3_text"
                src="../assets/p3_text.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p3_phone"
                src="../assets/p3_phone1.png"
                alt=""
              />
              <!-- <img
                object-fit="contain"
                class="p3_map"
                src="../assets/p3_map.png"
                alt=""
              /> -->
              <img
                object-fit="contain"
                class="p2_search"
                src="../assets/p2_search.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_cold"
                src="../assets/cold.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_star"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_bottom"
                src="../assets/bottom_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_top"
                src="../assets/top_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_star2"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_rocket"
                src="../assets/rocket.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_dimon"
                src="../assets/bluedimon.png"
                alt=""
              />
            </div>
          </div>
          <img
            object-fit="contain"
            class="bottom_arrow"
            src="../assets/arrow.png"
            alt=""
          />
        </div>
        <div class="swiper-slide">
          <div class="bg_img"></div>
          <div class="svg_box">
            <svg
              class="waves"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
              shape-rendering="auto"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160,35.6 c 30,0,58-6.6,88-6.6 s58,6.6,88,6.6s58-6.6,88-6.6s58,6.6,88,6.6V59h-352V35.6z"
                />
              </defs>
              <g class="parallax">
                <use xlink:href="#gentle-wave" x="48" y="0" fill="#fffbeb" />
                <use xlink:href="#gentle-wave" x="48" y="0" fill="#ffcc22" />
              </g>
            </svg>
          </div>
          <div class="p2_container">
            <div class="p2_box">
              <img
                object-fit="contain"
                class="p2_title"
                src="../assets/p4_title.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p2_sub"
                src="../assets/p4_sub.png"
                alt=""
              />
              <div class="p3_span">&nbsp;</div>
              <img
                object-fit="contain"
                class="p4_text"
                src="../assets/p4_text.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p4_phone"
                src="../assets/p4_phone.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p2_search"
                src="../assets/p2_search.png"
                alt=""
              /><!--  -->
              <img
                object-fit="contain"
                class="white_cold"
                src="../assets/cold.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_star"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_bottom"
                src="../assets/bottom_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_top"
                src="../assets/top_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_star2"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_rocket"
                src="../assets/rocket.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_dimon"
                src="../assets/bluedimon.png"
                alt=""
              />
            </div>
          </div>
          <img
            object-fit="contain"
            class="bottom_arrow"
            src="../assets/arrow.png"
            alt=""
          />
        </div>
        <div class="swiper-slide">
          <div class="bg_img"></div>
          <div class="svg_box">
            <svg
              class="waves"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
              shape-rendering="auto"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160,35.6 c 30,0,58-6.6,88-6.6 s58,6.6,88,6.6s58-6.6,88-6.6s58,6.6,88,6.6V59h-352V35.6z"
                />
              </defs>
              <g class="parallax">
                <use xlink:href="#gentle-wave" x="48" y="0" fill="#fffbeb" />
                <use xlink:href="#gentle-wave" x="48" y="0" fill="#ffcc22" />
              </g>
            </svg>
          </div>
          <div class="p2_container">
            <div class="p2_box">
              <img
                object-fit="contain"
                class="p2_title"
                src="../assets/p5_title.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p2_sub"
                src="../assets/p5_sub.png"
                alt=""
              />
              <div class="p3_span">&nbsp;</div>
              <img
                object-fit="contain"
                class="p5_text"
                src="../assets/p5_text.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p5_phone"
                src="../assets/p5_phone.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p2_search"
                src="../assets/p2_search.png"
                alt=""
              /><!--  -->
              <img
                object-fit="contain"
                class="white_cold"
                src="../assets/cold.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_star"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_bottom"
                src="../assets/bottom_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_top"
                src="../assets/top_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_star2"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_rocket"
                src="../assets/rocket.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_dimon"
                src="../assets/bluedimon.png"
                alt=""
              />
            </div>
          </div>
          <img
            object-fit="contain"
            class="bottom_arrow"
            src="../assets/arrow.png"
            alt=""
          />
        </div>
        <div class="swiper-slide">
          <div class="bg_img"></div>
          <div class="svg_box">
            <svg
              class="waves"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
              shape-rendering="auto"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160,35.6 c 30,0,58-6.6,88-6.6 s58,6.6,88,6.6s58-6.6,88-6.6s58,6.6,88,6.6V59h-352V35.6z"
                />
              </defs>
              <g class="parallax">
                <use xlink:href="#gentle-wave" x="48" y="0" fill="#fffbeb" />
                <use xlink:href="#gentle-wave" x="48" y="0" fill="#ffcc22" />
              </g>
            </svg>
          </div>
          <div class="p2_container">
            <div class="p2_box">
              <img
                object-fit="contain"
                class="p2_title"
                src="../assets/p6_title.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p2_sub"
                src="../assets/p6_sub.png"
                alt=""
              />
              <div class="p3_span">&nbsp;</div>
              <img
                object-fit="contain"
                class="p6_text"
                src="../assets/p6_text.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p6_phone"
                src="../assets/p6_phone.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p6_img1"
                src="../assets/p6_img1.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p6_img2"
                src="../assets/p6_img2.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p2_search"
                src="../assets/p2_search.png"
                alt=""
              /><!--  -->
              <img
                object-fit="contain"
                class="white_cold"
                src="../assets/cold.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_star"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_bottom"
                src="../assets/bottom_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_top"
                src="../assets/top_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_star2"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_rocket"
                src="../assets/rocket.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="white_dimon"
                src="../assets/bluedimon.png"
                alt=""
              />
            </div>
          </div>
          <img
            object-fit="contain"
            class="bottom_arrow"
            src="../assets/arrow.png"
            alt=""
          />
        </div>
        <div class="swiper-slide bg_yel">
          <div class="bg_img"></div>
          <div class="p1_container">
            <div class="p1_box">
              <img
                object-fit="contain"
                class="left_icon"
                src="../assets/left_icon1.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="right_icon"
                src="../assets/right_icon1.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p7_logo"
                src="../assets/p7_logo.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p7_num"
                src="../assets/p7_num.png"
                alt=""
              />
              <!--  -->
              <img
                object-fit="contain"
                class="yel_star"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="yel_rocket"
                src="../assets/rocket.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="yel_dimon"
                src="../assets/dimon.png"
                alt=""
              />
            </div>
          </div>
          <img
            object-fit="contain"
            class="foot_left"
            src="../assets/foot_left.png"
            alt=""
          />
          <img
            object-fit="contain"
            class="foot_right"
            src="../assets/foot_right.png"
            alt=""
          /><img
            object-fit="contain"
            class="bottom_arrow"
            src="../assets/arrow.png"
            alt=""
          />
        </div>
        <div class="swiper-slide bg_yel">
          <div class="bg_img"></div>
          <div class="p1_container">
            <div class="p1_box">
              <img
                object-fit="contain"
                class="left_icon"
                src="../assets/left_icon1.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="right_icon"
                src="../assets/right_icon1.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p1_logo"
                src="../assets/p1_logo.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p1_slogan"
                src="../assets/p1_slogan.png"
                alt=""
              />

              <div class="contact_box">
                <img
                object-fit="contain"
                class="p8_img p8_email"
                src="../assets/p8_email.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p8_img p8_phone"
                src="../assets/p8_phone.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p8_img p8_wx"
                src="../assets/p8_wx.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p8_img p8_weibo"
                src="../assets/p8_weibo.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="p8_img p8_redbook"
                src="../assets/p8_redbook.png"
                alt=""
              />
              </div>
              

              <img
                v-if="!showMenu"
                object-fit="contain"
                class="p1_code"
                src="../assets/p1_code.png"
                alt=""
              />
              <!--  -->
              <img
                object-fit="contain"
                class="yel_star"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="yel_bottom"
                src="../assets/bottom_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="yel_top"
                src="../assets/top_img.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="yel_star2"
                src="../assets/star.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="yel_rocket"
                src="../assets/rocket.png"
                alt=""
              />
              <img
                object-fit="contain"
                class="yel_dimon"
                src="../assets/dimon.png"
                alt=""
              />
            </div>
          </div>
          <img
            object-fit="contain"
            class="foot_left2"
            src="../assets/foot_left.png"
            alt=""
          />
          <img
            object-fit="contain"
            class="foot_right2"
            src="../assets/foot_right.png"
            alt=""
          />
          <div class="beian_box">
            <div class="beian_contain">
              <p>
                ©2018
                聚否互联网络科技(成都)有限公司&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a
                  target="_blank"
                  href="https://beian.miit.gov.cn/"
                  >蜀ICP备18028497号-1</a
                >
              </p>
              <p style="padding-top: 2px">
                <img class="p8_beian" src="../assets/p8_beian.png" /><a
                  target="_blank"
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010802000693"
                  >川公网安备51010802000693号</a
                >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;联系我们：028-69215179
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧滚动条 -->
    <div class="pagination">
      <div
        v-for="(item, index) in 8"
        :key="index"
        :class="
          index == pageindex
            ? pageindex == 0 || pageindex == 6 || pageindex == 7
              ? 'swiper_item swiper_active_yel'
              : 'swiper_item swiper_active_white'
            : pageindex == 0 || pageindex == 6 || pageindex == 7
            ? 'swiper_item swiper_bg_yel'
            : 'swiper_item swiper_bg_white'
        "
      ></div>
    </div>
    <div class="menu" v-if="showMenu">
      <div class="mask_box">
        <div class="mask_head">
          <img
            object-fit="contain"
            class="mask_logo"
            src="../assets/head_logo.png"
            alt=""
          />
          <img
            @click="changeMenu"
            object-fit="contain"
            class="mask_close"
            src="../assets/close.png"
            alt=""
          />
        </div>
        <div class="mask_line"></div>
        <img
          @click="changeSlider(0)"
          object-fit="contain"
          class="home"
          src="../assets/home.png"
          alt=""
        />
        <img
          @click="changeSlider(1)"
          object-fit="contain"
          class="product"
          src="../assets/product.png"
          alt=""
        />
        <img
          @click="changeSlider(6)"
          object-fit="contain"
          class="partner"
          src="../assets/partner.png"
          alt=""
        />
        <img
          @click="changeSlider(7)"
          object-fit="contain"
          class="contact"
          src="../assets/contact.png"
          alt=""
        />
        <img
          object-fit="contain"
          class="mask_code"
          src="../assets/mask_code.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
let mySwiper = null;
import { Swiper } from "swiper"; //swiper 插件：//swiper.com.cn
import "swiper/css";
export default {
  name: "HomeView",
  data() {
    return {
      showMenu: false,
      pageindex: 0,
    };
  },
  mounted() {
    this.swiperInit();
  },
  methods: {
    swiperInit: function () {
      //初始化
      let _this = this;
      mySwiper = new Swiper(".swiper_box", {
        direction: "vertical",
        on: {
          slideChangeTransitionEnd: function () {
            //swiper切换结束事件
            // alert(this.activeIndex);
            _this.pageindex = this.activeIndex;
          },
        },
        // pagination: {
        // 	el: '.swiper-pagination',
        // },
      });
    },
    changeSlider: function (index) {
      mySwiper.slideTo(index, 1000, true); //index、duration、是否触发动画回调
      this.changeMenu();
    },
    changeMenu: function () {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  width: 100%;
  height: 100%;
  .menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    background-color: rgba(0, 0, 0, 0.76);
    .mask_box {
      position: relative;
      // padding-top: 9.9136%;
      width: 100%;
      height: 100%;
      .mask_head {
        width: 100%;
        height: 4.9876%;
        position: absolute;
        top: 2.7709%;
        left: 0;
        padding: 0 43px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .mask_logo {
          height: 100%;
        }
        .mask_close {
          height: 45.1612%;
        }
      }
      .mask_line {
        position: absolute;
        width: 93.3333%;
        top: 9.1133%;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 1px solid #f2f2f2;
      }
      .home {
        position: absolute;
        top: 23.7684%;
        left: 50%;
        transform: translateX(-50%);
        height: 5.4187%;
      }
      .product {
        position: absolute;
        top: 35.5911%;
        left: 50%;
        transform: translateX(-50%);
        height: 5.4187%;
      }
      .partner {
        position: absolute;
        top: 47.4137%;
        left: 50%;
        transform: translateX(-50%);
        height: 5.4187%;
      }
      .contact {
        position: absolute;
        top: 59.1133%;
        left: 50%;
        transform: translateX(-50%);
        height: 5.4187%;
      }
      .mask_code {
        position: absolute;
        top: 78.3866%;
        left: 50%;
        transform: translateX(-50%);
        height: 13.7315%;
      }
    }
  }
  .foot_left {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 5.1108%;
    z-index: 99;
  }
  .foot_right {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 7.697%;
    z-index: 99;
  }
  .foot_left2 {
    position: fixed;
    bottom: 4.6797%;
    left: 0;
    height: 5.1108%;
    z-index: 99;
  }
  .foot_right2 {
    position: fixed;
    bottom: 4.6797%;
    right: 0;
    height: 7.697%;
    z-index: 99;
  }
  header {
    width: 100%;
    height: 4.9876%;
    position: fixed;
    top: 2.7709%;
    left: 0;
    padding: 0 43px;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head_logo {
      height: 100%;
    }
    .nav_btn {
      width: 30px;
      height: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      padding: 15px 0 15px 30px;
      box-sizing: content-box;
      .nav_item {
        width: 100%;
        height: 4px;
        background-color: #000;
        border-radius: 90px;
      }
    }
  }
  .line {
    position: fixed;
    width: 93.3333%;
    top: 9.1133%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    border-bottom: 1px solid #f2f2f2;
  }
  .svg_box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 19.2118%;
    z-index: 2;
  }
  .swiper_box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    .swiper-slide {
      position: relative;
      .bottom_arrow {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        top: 90.9482%;
        height: 2.3399%;
        z-index: 3;
        animation: arrow_ani 2.2s cubic-bezier(0.25, 0.1, 0.25, 0.1) infinite;
      }
      .beian_box {
        height: 4.6798%;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #040000;
        z-index: 101;
        .beian_contain {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            font-size: 16px;
            color: #c6c6c6;
            line-height: 22px;
            display: flex;
            align-items: center;
            a {
              color: #c6c6c6;
            }
            .p8_beian {
              width: 17px;
              height: 17px;
            }
          }
        }
      }
    }
    .p1_container {
      width: 88.5333%;
      height: 90.1477%;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      border-radius: 80px 80px 0 0;
      .p1_box {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        overflow: hidden;
        .left_icon {
          position: absolute;
          top: 0;
          left: 0;
          height: 8.948%;
        }
        .right_icon {
          position: absolute;
          top: 0;
          right: 0;
          height: 10.9286%;
        }
        .yel_star {
          position: absolute;
          top: 0;
          left: 23.3433%;
          height: 3.4153%;
        }
        .yel_bottom {
          position: absolute;
          top: 15.8469%;
          left: 6.024%;
          height: 4.5081%;
        }
        .yel_top {
          position: absolute;
          top: 29.3032%;
          right: 4.4%;
          height: 5.3961%;
        }
        .yel_star2 {
          position: absolute;
          top: 74.5901%;
          right: 3.7333%;
          height: 3.142%;
        }
        .yel_rocket {
          position: absolute;
          top: 84.6994%;
          left: 1.066%;
          height: 6.7622%;
        }
        .yel_dimon {
          position: absolute;
          top: 90.3688%;
          right: 11.8666%;
          height: 3.4153%;
        }
        .p1_logo {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 4.0983%;
          height: 8.0601%;
        }
        .p1_slogan {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 17.0081%;
          height: 13.183%;
          // margin-top: 4.8497%;
        }
        .p1_text {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 31.2158%;
          height: 4.8602%;
          // margin-top: 1.0245%;
        }
        .p1_phone {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 37.1584%;
          height: 34.0163%;
          // margin-top: 0.4781%;
        }
        .p1_code {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 76.5027%;
          height: 11.1338%;
          // margin-top: 2.0491%;
          // margin-bottom: 13.7295%;
        }
        .p7_logo {
          height: 72.4726%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 9.153%;
        }
        .p7_num {
          height: 4.2349%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 84.0163%;
        }
        .contact_box {
          position: absolute;
          top: 34.0163%;
          left: 50%;
          width: fit-content;
          height: 34.153%;
          transform: translateX(-50%);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .p8_img {
            height: 9%;
            // position: absolute;
            // left: 24.3975%;
          }
          .p8_email {
            margin-top: 0%;
          }
          .p8_phone {
            margin-top: 9%;
          }
          .p8_wx {
            margin-top: 24%;
          }
          .p8_weibo {
            margin-top: 9%;
          }
          .p8_redbook {
            margin-top: 9%;
          }
        }
      }
    }
    .p2_container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      .p2_box {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .white_cold {
          position: absolute;
          top: 13.7931%;
          left: 14.9333%;
          height: 3.0788%;
        }
        .white_bottom {
          position: absolute;
          top: 20.3201%;
          left: 10.6666%;
          height: 3.0788%;
        }
        .white_star {
          position: absolute;
          top: 20.5565%;
          right: 8%;
          height: 2.5862%;
        }
        .white_star2 {
          position: absolute;
          top: 37.5615%;
          left: 11.0666%;
          height: 3.1403%;
        }
        .white_top {
          position: absolute;
          top: 37.0073%;
          right: 12.5333%;
          height: 3.3866%;
        }
        .white_rocket {
          position: absolute;
          top: 77.032%;
          left: 10.4%;
          height: 4.9261%;
        }
        .white_dimon {
          position: absolute;
          top: 75.8916%;
          right: 15.9333%;
          height: 4.1874%;
        }
        .p2_title {
          position: absolute;
          top: 13.5467%;
          left: 50%;
          transform: translateX(-50%);
          height: 11.3916%;
        }
        .p2_sub {
          position: absolute;
          top: 24.5073%;
          left: 50%;
          transform: translateX(-50%);
          height: 2.8325%;
        }
        .p2_span {
          position: absolute;
          top: 29.064%;
          left: 50%;
          transform: translateX(-50%);
          width: 6.9333%;
          height: 0.4926%;
          background-color: #ffcf1e;
        }
        .p2_text {
          position: absolute;
          top: 31.096%;
          left: 50%;
          transform: translateX(-50%);
          height: 6.2807%;
        }

        .p2_phone {
          position: absolute;
          top: 37.5615%;
          left: 50%;
          transform: translateX(-50%);
          height: 37.2536%;
        }

        .p2_search {
          position: absolute;
          top: 79.4334%;
          left: 50%;
          transform: translateX(-50%);
          height: 5.1108%;
        }
        .p3_title {
          position: absolute;
          top: 13.5467%;
          left: 50%;
          transform: translateX(-50%);
          height: 11.3916%;
        }
        .p3_sub {
          position: absolute;
          top: 24.5073%;
          left: 50%;
          transform: translateX(-50%);
          height: 2.8325%;
        }
        .p3_text {
          position: absolute;
          top: 31.096%;
          left: 50%;
          transform: translateX(-50%);
          height: 7.3891%;
        }
        .p3_map {
          position: absolute;
          top: 44.9507%;
          left: 20%;
          height: 9.1133%;
        }
        .p3_phone {
          position: absolute;
          top: 40.1477%;
          left: 50%;
          transform: translateX(-50%);
          height: 37.3152%;
        }
        .p3_span {
          position: absolute;
          top: 29.064%;
          left: 50%;
          transform: translateX(-50%);
          width: 6.9333%;
          height: 0.4926%;
          background-color: #ffcf1e;
        }
        .p4_text {
          position: absolute;
          top: 31.4039%;
          left: 50%;
          transform: translateX(-50%);
          height: 7.4507%;
        }
        .p4_phone {
          position: absolute;
          top: 40.394%;
          left: 50%;
          transform: translateX(-50%);
          height: 34.9137%;
        }
        .p5_text {
          position: absolute;
          top: 31.4039%;
          left: 50%;
          transform: translateX(-50%);
          height: 7.4507%;
        }
        .p5_phone {
          position: absolute;
          top: 35.098%;
          left: 50%;
          transform: translateX(-50%);
          height: 43.1034%;
        }
        .p6_text {
          position: absolute;
          top: 31.4039%;
          left: 50%;
          transform: translateX(-50%);
          height: 7.4507%;
        }
        .p6_phone {
          position: absolute;
          top: 41.2561%;
          left: 50%;
          transform: translateX(-50%);
          height: 35.0985%;
        }
        .p6_img1 {
          position: absolute;
          top: 43.5344%;
          left: 32.2666%;
          // transform: translateX(-50%);
          height: 12.3152%;
        }
        .p6_img2 {
          position: absolute;
          top: 53.3866%;
          left: 60%;
          // transform: translateX(-50%);
          height: 11.6995%;
        }
      }
    }
    .bg_img {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url("../assets/bg_img1.png");
      background-repeat: no-repeat;
      background-size: cover;
      -moz-background-size: cover;
    }
  }
  .pagination {
    width: 10px;
    height: 11.4532%;
    position: fixed;
    right: 3.3333%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    .swiper_item {
      width: 100%;
      height: 10px;
      transition: all 0.3s linear;
      // cursor: pointer;
    }
    .swiper_active_yel {
      height: 32px;
      background-color: #ffffff;
      border-radius: 90px;
    }
    .swiper_active_white {
      height: 32px;
      background-color: #ffcf1e;
      border-radius: 90px;
    }
    .swiper_bg_yel {
      background-color: #ffeca5;
      border-radius: 50%;
    }
    .swiper_bg_white {
      background-color: #cccccc;
      border-radius: 50%;
    }
  }
}
.bg_yel {
  background-color: #ffcf1e;
}
.waves {
  width: 100%;
  height: 100%;
  margin-bottom: -7px;
}
.parallax > use:nth-child(1) {
  animation: move-forever 7s linear infinite;
  animation-delay: -2s;
  animation-duration: 7s;
  animation-fill-mode: none;
  // animation-fill-mode: both;
}
.parallax > use:nth-child(2) {
  animation: move-forever 12s linear infinite;
  animation-delay: -3s;
  animation-duration: 12s;
  animation-fill-mode: none;
  // animation-fill-mode: both;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
@-webkit-keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
@keyframes arrow_ani {
  0% {
    top: 90.9482%;
  }
  50% {
    top: 92.9482%;
  }
  100% {
    top: 90.9482%;
  }
}
@-webkit-keyframes arrow_ani {
  0% {
    top: 90.9482%;
  }
  50% {
    top: 92.9482%;
  }
  100% {
    top: 90.9482%;
  }
}
</style>
